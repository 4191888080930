export default function formValidation(data) {
  let errors = {};
  if (!validateEmail(data.email)) errors = { ...errors, email: true };
  if (!validateName(data.firstName)) errors = { ...errors, firstName: true };
  if (!validateName(data.lastName)) errors = { ...errors, lastName: true };
  if (!validatePhone(data.phone)) errors = { ...errors, phone: true };

  if (Object.keys(errors).length > 0) return errors;
  else return true;
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateName(name) {
  if (!name || name.length < 1) {
    return false;
  }
  return true;
}

export function validatePhone(phone) {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  return re.test(phone);
}
