import React from 'react'

import moment from 'moment-timezone';
export default function AuthDomicileColumns(props) {


    const {reservedslots, slot} = props;
    const columns = ['address', 'city', 'cp', 'etage', 'code1', 'code2', 'escalier','appartement']
    const columns_to_show = ['etage','code1','code2', 'escalier', 'appartement']
    return (
        columns.map((column) =>(
        <div
            style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            }}
        >
            {
            reservedslots.find((reservedslot) =>
            moment(slot).isSame(reservedslot.date)
            ) &&
            reservedslots.find((reservedslot) =>
                moment(slot).isSame(reservedslot.date)
            )[column]
            &&
            (columns_to_show.some((c) => c === column) ? column + ' ' + reservedslots.find((reservedslot) =>
                moment(slot).isSame(reservedslot.date)
            )[column]
            :
            reservedslots.find((reservedslot) =>
                moment(slot).isSame(reservedslot.date)
            )[column])
            }
        </div>
        ))
    )
}
