import React from 'react';

import Home from './components/Home';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import Cancel from './components/cancel/Cancel';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path='/cancel/:token'>
            <Cancel />
          </Route>
          <Home />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
