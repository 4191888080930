import moment from 'moment';
import { isCoronaWeekday, isDomicilesDay } from './isWeekday';

export default function getDomicilesTimeSlots(date, labo) {
  let dates = [];
  if (labo === 'mozart') {
    const now = moment(date).startOf('day').hour(8).minute(30).seconds(0);
    const deadline = moment(date).hour(12).minute(30).seconds(59);

    while (now.diff(deadline) < 0) {
      if (now > moment() && isDomicilesDay(new Date(moment(now)), 'mozart')) {
        dates.push(now.format('YYYY-MM-DD HH:mm'));
      }
      now.add(30, 'minutes');
    }
  }

  // else if (labo === 'thiais' ) {
  //   const now = moment(date).startOf('day').hour(8).minute(30).seconds(0);
  //   const deadline = moment(date).hour(12).minute(30).seconds(59);

  //   while (now.diff(deadline) < 0) {
  //     if (now > moment() && isDomicilesDay(new Date(moment(now)), 'thiais')) {
  //       dates.push(now.format('YYYY-MM-DD HH:mm'));
  //     }
  //     now.add(30, 'minutes');
  //   }
  // }

  return dates;
}
