import moment from 'moment';
import isWeekday, { isMozartWeekday } from './isWeekday';

export default function getTimeSlots(date, labo) {
	let dates = [];

	if (labo === 'laboxv') {
		const deadline = moment(date).hour(17).minute(59).seconds(59);

		const now = moment(date).startOf('day').hour(7).minute(5).seconds(0);
		while (now.diff(deadline) < 0) {
			if (
				now > moment() &&
				isWeekday(new Date(moment(now)), 'laboxv') &&
				now >= moment(now).hour(7).minute(30)
			) {
				dates.push(now.format('YYYY-MM-DD HH:mm'));
			}
			now.add(5, 'minutes');
		}
	} else if (labo === 'mozart') {
		const deadline = moment(date).hour(17).minute(59).seconds(59);

		const now = moment(date).startOf('day').hour(7).minute(30).seconds(0);
		while (now.diff(deadline) < 0) {
			if (now > moment() && isWeekday(new Date(moment(now)), 'mozart')) {
				dates.push(now.format('YYYY-MM-DD HH:mm'));
			}
			now.add(5, 'minutes');
		}
	} else if (labo === 'thiais') {
		const now = moment(date).startOf('day').hour(13).minute(35).seconds(0);
		const deadline = moment(date).hour(16).minute(50).seconds(59);

		while (now.diff(deadline) < 0) {
			if (now > moment() && isWeekday(new Date(moment(now)), 'thiais')) {
				dates.push(now.format('YYYY-MM-DD HH:mm'));
			}
			now.add(5, 'minutes');
		}
	}

	return dates;
}
