import React, { useState } from 'react';
import { Form as SemanticForm, Message } from 'semantic-ui-react';
import formValidation from './validateForm';
import Axios from 'axios';
import { api } from '../api';

export default function DomicileForm(props) {
  const { slot, labo } = props;
  const [formdata, setformdata] = useState({});
  const [errors, seterrors] = useState(null);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState(null);

  async function submit() {
    const errors = formValidation(formdata);
    if (errors !== true) {
      seterrors(errors);
    } else {
      setloading(true);
      const data = { ...formdata, slot: props.slot, labo };

      try {
        await Axios.post(api + 'slots/', data);
        setmessage({
          type: 'success',
          message:
            'Votre rendez-vous est pris! Vous recevrez une confirmation par mail.',
        });
      } catch (e) {
        setloading(false);
        setmessage({
          type: 'error',
          message: 'Erreur lors de la prise de votre rendez-vous.',
        });
      }
    }
  }

  return (
    <>
      <div>
        <p>
            <strong>Nous passerons chez vous entre 8h30 et 12h30, selon nos disponibilités.</strong>
        </p>
      </div>
      <div
        style={{
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <SemanticForm>
          <SemanticForm.Field required error={errors?.lastName}>
            <label>Nom</label>
            <input
              onChange={(e) =>
                setformdata({ ...formdata, lastName: e.target.value })
              }
            ></input>
          </SemanticForm.Field>
          <SemanticForm.Field required error={errors?.firstName}>
            <label>Prénom</label>
            <input
              onChange={(e) =>
                setformdata({ ...formdata, firstName: e.target.value })
              }
            ></input>
          </SemanticForm.Field>
          {slot.type === 'domiciles' && (
          <>
          <SemanticForm.Field required>
            <label>Adresse</label>
            <SemanticForm.TextArea onChange={(e) =>
                setformdata({ ...formdata, address: e.target.value })
              }>  
            </SemanticForm.TextArea>
          </SemanticForm.Field>
          <SemanticForm.Field required>
            <label>Code postal</label>
            <input onChange={(e) =>
                setformdata({ ...formdata, cp: e.target.value })
              }>  
            </input>
          </SemanticForm.Field>
          <SemanticForm.Field required>
            <label>Ville</label>
            <input onChange={(e) =>
                setformdata({ ...formdata, city: e.target.value })
              }>  
            </input>
          </SemanticForm.Field>
          <SemanticForm.Field>
            <label>Appartement</label>
            <input onChange={(e) =>
                setformdata({ ...formdata, appartement: e.target.value })
              }>  
            </input>
          </SemanticForm.Field>
          <SemanticForm.Field>
            <label>Etage</label>
            <input onChange={(e) =>
                setformdata({ ...formdata, etage: e.target.value })
              }>  
            </input>
          </SemanticForm.Field>
          <SemanticForm.Field>
            <label>Escalier</label>
            <input onChange={(e) =>
                setformdata({ ...formdata, escalier: e.target.value })
              }>  
            </input>
          </SemanticForm.Field>
          <SemanticForm.Field>
            <label>Code 1</label>
            <input onChange={(e) =>
                setformdata({ ...formdata, code1: e.target.value })
              }>  
            </input>
          </SemanticForm.Field>
          <SemanticForm.Field>
            <label>Code 2</label>
            <input onChange={(e) =>
                setformdata({ ...formdata, code2: e.target.value })
              }>  
            </input>
          </SemanticForm.Field>
          </>
          )}
          <SemanticForm.Field required error={errors?.phone}>
            <label>Téléphone</label>
            <input
              onChange={(e) =>
                setformdata({ ...formdata, phone: e.target.value })
              }
            ></input>
          </SemanticForm.Field>
          <SemanticForm.Field required error={errors?.email}>
            <label>Email</label>
            <input
              onChange={(e) =>
                setformdata({ ...formdata, email: e.target.value })
              }
            ></input>
          </SemanticForm.Field>
          {slot.type === 'selles' && (
            <SemanticForm.Field>
              <label>Médecin prescripteur</label>
              <input
                onChange={(e) =>
                  setformdata({ ...formdata, medecin: e.target.value })
                }
              ></input>
            </SemanticForm.Field>
          )}
          <SemanticForm.Button
            disabled={loading}
            onClick={() => submit()}
            color='green'
            content='Prendre rendez-vous'
          ></SemanticForm.Button>
        </SemanticForm>
      </div>
      {message && (
        <div
          style={{
            display: 'flex',
            marginTop: '20px',
            justifyContent: 'center',
          }}
        >
          {message.type === 'success' ? (
            <Message success content={message.message}></Message>
          ) : (
            <Message error content={message.message}></Message>
          )}
        </div>
      )}
    </>
  );
}
