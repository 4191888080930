import Axios from 'axios';
import { api } from '../api';

export default async function blockSlot(slot, type, setdeleteerror, get, labo) {
  try {
    await Axios.post(api + 'slots/block/', { type, slot, labo });
  } catch (e) {
    setdeleteerror(true);
    setTimeout(() => {
      setdeleteerror(false);
    }, 4000);
  } finally {
    get();
  }
}
