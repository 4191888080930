export default function isWeekday(date, labo) {
	const day = date.getDay();
	const hour = date.getHours();
	const minute = date.getMinutes();

	const day_date = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	if (labo === 'laboxv') {
		if (day_date == 18 && month == 3 && year == 2022) {
			return hour == 0 || (hour >= 9 && hour < 14);
		} else {
			return (
				(day !== 0 && !(day === 6 && hour >= 16)) ||
				hour == 0 || //glitch for datepicker selection
				(day == 0 && hour >= 9 && hour < 14)
			); // sundays
		}
	} else if (labo === 'mozart') {
		if (day_date == 18 && month == 3 && year == 2022) {
			return false;
		}
		return (
			(day !== 0 && !(day === 6 && hour >= 16)) ||
			hour == 0 || //glitch for datepicker selection
			(day == 0 && hour >= 9 && hour < 14)
		); // sundays
	} else if (labo === 'thiais') {
		if (day_date == 18 && month == 3 && year == 2022) {
			return false;
		}
		return day !== 0 && day !== 6;
	} else {
		return day !== 0;
	}
}

export function isDomicilesDay(date, labo) {
	const day = date.getDay();
	const day_date = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	if (day_date == 18 && month == 3 && year == 2022) {
		return false;
	}

	if (labo === 'mozart') {
		return day === 2 || day === 4;
	}
	if (labo === 'thiais') {
		return day > 0 && day < 6;
	}
}

export function isSellesDay(date, labo) {
	const day = date.getDay();
	const day_date = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	if (day_date == 18 && month == 3 && year == 2022) {
		return false;
	}

	return day > 0 && day < 5;
}

export function isCoronaWeekday(date, labo) {
	const day = date.getDay();

	const hour = date.getHours();

	const minute = date.getMinutes();

	const day_date = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	if (labo === 'laboxv') {
		if (day_date == 18 && month == 3 && year == 2022) {
			return hour == 0 || (hour >= 9 && hour < 14);
		} else {
			return (
				(day !== 0 && !(day === 6 && hour >= 16)) ||
				hour == 0 || //glitch for datepicker selection
				(day == 0 && hour >= 9 && hour < 14) // sundays
			);
		}
	} else if (labo === 'mozart') {
		if (day_date == 18 && month == 3 && year == 2022) {
			return hour == 0 || (hour >= 9 && hour < 14);
		} else {
			return (
				(day !== 0 && !(day === 6 && hour >= 16)) ||
				hour == 0 || //glitch for datepicker selection
				(day == 0 && hour >= 9 && hour < 14) // sundays
			);
		}
	} else if (labo === 'thiais') {
		if (day_date == 18 && month == 3 && year == 2022) {
			return hour == 0 || (hour >= 9 && hour < 14);
		} else {
			return day !== 0 && day !== 6;
		}
	}
}
