import React, { useState } from 'react';
import { Form as SemanticForm, Message } from 'semantic-ui-react';
import formValidation from './validateForm';
import Axios from 'axios';
import { api } from '../api';

export default function Form(props) {
  const { slot, labo } = props;
  const [formdata, setformdata] = useState({});
  const [errors, seterrors] = useState(null);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState(null);

  async function submit() {
    const errors = formValidation(formdata);
    if (errors !== true) {
      seterrors(errors);
    } else {
      setloading(true);
      const data = { ...formdata, slot: props.slot, labo };

      try {
        await Axios.post(api + 'slots/', data);
        setmessage({
          type: 'success',
          message:
            'Votre rendez-vous est pris! Vous recevrez une confirmation par mail.',
        });
      } catch (e) {
        setloading(false);
        setmessage({
          type: 'error',
          message: 'Erreur lors de la prise de votre rendez-vous.',
        });
      }
    }
  }

  return (
    <>
      <div>
        {labo === 'laboxv' ? (
          <p>
            Nous sommes situés au{' '}
            <strong>353, rue de Vaugirard 75015 Paris</strong> (croisement rue
            de la Convention et rue de Vaugirard).
          </p>
        ) : 
        labo === 'mozart' ? (
          <p>
            Nous sommes situés au{' '}
            <strong>16, avenue Mozart 75016 Paris. </strong>
          </p>
        )
      : 
      <p>
            Nous sommes situés au{' '}
            <strong>19 rue
                    Pavé de Grignon 94320 Thiais. </strong>
          </p>
      }
        {/* <p>Quelques recommandations: </p>

        {slot.type === 'corona' && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
              Si vous venez pour une recherche directe par PCR (écouvillonage
              nasopharyngé) de Coronavirus :{' '}
            </p> 
            <ul style={{ marginTop: 0 }}>
              <li>Venez seul(e)</li>
              <li>A l'heure précise svp</li>
              <li>Avec des gants et un masque</li>
              <li>
                Essayez de ne pas vous moucher pendant les 10 minutes qui
                précèdent le rendez-vous (car nous allons vous prélever ces
                sécrétions)
              </li>
              <li>
                N'oubliez pas votre <strong>ordonnance</strong>, votre{' '}
                <strong>carte vitale</strong>, votre{' '}
                <strong>carte mutuelle</strong> et{' '}
              </li>
            </ul>
          </div>
        )}
        {slot.type === 'normal' && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
             <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
              Si vous venez uniquement pour une sérologie (prélèvement sanguin)
              :{' '}
            </p> 
            <ul style={{ marginTop: 0 }}>
              <li>Venez avec gants et masque !</li>
              <li>
                Autrement, aucune précaution n'est requise (vous pouvez déjeuner
                normalement)
              </li>
              <li>Soyez ponctuel pour l'heure du rendez-vous svp</li>
            </ul>
          </div>
        )} */}
      </div>
      <div
        style={{
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <SemanticForm>
          <SemanticForm.Field required error={errors?.lastName}>
            <label>Nom</label>
            <input
              onChange={(e) =>
                setformdata({ ...formdata, lastName: e.target.value })
              }
            ></input>
          </SemanticForm.Field>
          <SemanticForm.Field required error={errors?.firstName}>
            <label>Prénom</label>
            <input
              onChange={(e) =>
                setformdata({ ...formdata, firstName: e.target.value })
              }
            ></input>
          </SemanticForm.Field>
          <SemanticForm.Field required error={errors?.phone}>
            <label>Téléphone</label>
            <input
              onChange={(e) =>
                setformdata({ ...formdata, phone: e.target.value })
              }
            ></input>
          </SemanticForm.Field>
          <SemanticForm.Field required error={errors?.email}>
            <label>Email</label>
            <input
              onChange={(e) =>
                setformdata({ ...formdata, email: e.target.value })
              }
            ></input>
          </SemanticForm.Field>
          {slot.type === 'selles' && (
            <SemanticForm.Field>
              <label>Médecin prescripteur</label>
              <input
                onChange={(e) =>
                  setformdata({ ...formdata, medecin: e.target.value })
                }
              ></input>
            </SemanticForm.Field>
          )}
          <SemanticForm.Button
            disabled={loading}
            onClick={() => submit()}
            color='green'
            content='Prendre rendez-vous'
          ></SemanticForm.Button>
        </SemanticForm>
      </div>
      {message && (
        <div
          style={{
            display: 'flex',
            marginTop: '20px',
            justifyContent: 'center',
          }}
        >
          {message.type === 'success' ? (
            <Message success content={message.message}></Message>
          ) : (
            <Message error content={message.message}></Message>
          )}
        </div>
      )}
    </>
  );
}
