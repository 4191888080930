import Axios from 'axios';
import { api } from '../api';

export default async function deleteSlot(slot, setdeleteerror, get) {
  if (window.confirm('Etes-vous sûr de vouloir retirer ce créneau?')) {
    try {
      await Axios.delete(api + 'slots/delete/' + slot.id);
    } catch (e) {
      setdeleteerror(true);
      setTimeout(() => {
        setdeleteerror(false);
      }, 4000);
    } finally {
      get();
    }
  }
}
