import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Sentry } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import Axios from 'axios';
import { api } from '../../api';
import { Message, Container } from 'semantic-ui-react';

export default function Cancel() {
  let { token } = useParams();

  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(false);

  const history = useHistory();

  const cancelSlot = async () => {
    try {
      if (
        window.confirm('Êtes-vous sûr de vouloir annuler votre rendez-vous ?')
      ) {
        await Axios.post(api + 'slots/cancel', { token });
        setloading(false);
        setTimeout(() => {
          history.push('/');
        }, 2500);
      }
    } catch (e) {
      console.log(e);
      seterror(true);
      setloading(false);
    }
  };

  useEffect(() => {
    cancelSlot();
  }, []);
  return (
    <Container style={{ marginTop: 20 }}>
      {loading ? (
        <Sentry></Sentry>
      ) : !error ? (
        <Message
          success
          content={
            'Votre rendez-vous a bien été annulé. Vous allez être redirigé vers la page principale.'
          }
        ></Message>
      ) : (
        <Message
          error
          content={
            "Erreur lors de l'annulation de votre rendez-vous. Vous avez sans doute déjà annulé votre rendez-vous ; sinon, veuillez nous contacter par mail à laboxv@laboxv.com ."
          }
        ></Message>
      )}
    </Container>
  );
}
