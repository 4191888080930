import moment from 'moment';
import { isCoronaWeekday } from './isWeekday';

export default function getCoronaTimeSlots(date, labo) {
	let dates = [];
	if (labo === 'laboxv') {
		const now = moment(date).startOf('day').hour(7).minute(30).seconds(0);
		const deadline = moment(date).hour(17).minute(59).seconds(59);

		while (now.diff(deadline) < 0) {
			if (now > moment() && isCoronaWeekday(new Date(moment(now)), 'laboxv')) {
				dates.push(now.format('YYYY-MM-DD HH:mm'));
			}
			now.add(5, 'minutes');
		}
	} else if (labo === 'mozart') {
		const now = moment(date).startOf('day').hour(7).minute(30).seconds(0);
		const deadline = moment(date).hour(17).minute(59).seconds(59);

		while (now.diff(deadline) < 0) {
			if (now > moment() && isCoronaWeekday(new Date(moment(now)), 'mozart')) {
				dates.push(now.format('YYYY-MM-DD HH:mm'));
				// if (now.isSame('2022-01-02', 'day')) {
				// 	const nowp = now.clone().add(1, 'minutes');
				// 	dates.push(nowp.format('YYYY-MM-DD HH:mm'));
				// 	const nowplus = now.clone().add(3, 'minutes');
				// 	dates.push(nowplus.format('YYYY-MM-DD HH:mm'));
				// }
			}
			now.add(5, 'minutes');
		}
	} else if (labo === 'thiais') {
		const now = moment(date).startOf('day').hour(13).minute(30).seconds(0);
		const deadline = moment(date).hour(16).minute(50).seconds(59);

		while (now.diff(deadline) < 0) {
			if (now > moment() && isCoronaWeekday(new Date(moment(now)), 'thiais')) {
				dates.push(now.format('YYYY-MM-DD HH:mm'));
			}
			now.add(5, 'minutes');
		}
	}

	return dates;
}
