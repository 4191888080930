import React from 'react';

export default function ProcedureSelles(props) {
	return (
		<div style={{ marginHorizontal: 30, textAlign: 'left', marginBottom: 20 }}>
			<div
				style={{
					border: '2px solid black',
					borderRadius: 5,
					padding: 5,
					textAlign: 'center',
				}}
			>
				<h2>
					NOUVELLE PROCÉDURE POUR L'EXAMEN COPRO-PARASITOLOGIQUE DES SELLES
				</h2>
			</div>
			<div style={{ marginTop: 15, marginBottom: 5 }}>
				La nouvelle nomenclature d'avril 2019 recommande l'examen des 3 salles
				différentes <u> émises à au moins 24 heures d'intervalle : </u>
			</div>
			<div style={{ color: 'red' }}>
				<u>À votre domicile</u> : 2 selles à apporter
			</div>
			<div>
				<ul style={{ marginTop: 0 }}>
					<li>
						Il vous faut recueillir à votre domicile 2 selles à 24h d'intervalle
						(la veille et l'avant-veille par exemple)
					</li>
					<li>
						Recueillir l'équivalent d'une "mandarine" de selles dans un bocal
						transparent type confiture (il n'a pas besoin d'être stérile) à
						chaque fois
					</li>
					<li>
						Conserver les 2 selles au frais au bord de la fenêtre, ou en bas du
						réfrigérateur
					</li>
					<li>Se rappeler de les prendre avec soi le jour du rendez-vous !</li>
				</ul>
			</div>
			<div style={{ color: 'green' }}>
				<u>Au laboratoire ou à domicile si vous habitez tout près</u> : 1 selle
				fraîche
			</div>
			<div>
				Veuillez vous présenter à l'heure fixée de votre rendez-vous, à jeun, et
				si possible sans avoir été à la selle ce matin-là (ventre plein).
				<ul style={{ marginTop: 0 }}>
					<li>Nous vous donnerons un purgatif à boire</li>
					<li>
						Vous vous retiendrez 15 minutes avant d'émettre une selle fraîche au
						laboratoire
					</li>
					<li>
						Dans les cas difficiles, vous irez prendre un petit-déjeuner pour
						forcer l'exonération.
					</li>
				</ul>
			</div>
			<br />
			<u>
				<strong>Recommandations annexes : </strong>
			</u>
			<div style={{ marginTop: 5 }}>
				<strong>
					{' '}
					1) FAIRE RÉALISER LES EXAMENS DE SELLES DE PRÉFÉRENCE <u>
						AVANT
					</u>{' '}
					TOUTE INVESTIGATION DIGESTIVE
				</strong>{' '}
				par imagerie (Fibroscopie ; Coloscopie ; Transit du grêle) ou au moins{' '}
				<u>une semaine après</u> cette investigation.
			</div>
			<div style={{ marginTop: 5 }}>
				<strong>
					2) MÉDICAMENTS À ÉVITER PENDANT LES 3 JOURS QUI PRÉCÈDENT LE
					RENDEZ-VOUS :{' '}
				</strong>{' '}
				<u>tous antibiotiques</u> ; <u>tous antiseptiques intestinaux</u>{' '}
				(ERCEFURYL, INTETRIX) ; <u>tous antiparasitaires</u> (FLAGYL, FASIGYNE,
				FLAGENTYL) ; <u>huile de paraffine</u> ; <u>suppositoires</u> (sauf
				GLYCÉRINE ou RECTOPANBILINE) ; <u>pansements intestinaux</u> (SMECTA,
				CHARBON, ACTAPULGITE, BEDELIX, CARBOSYLANE, CARBOSYMAG, GAVISCON,
				KAOLOGEAIS, MAALOX, PHOSPHALUGEL, POLYSILANE, POLYKARAYA...)
			</div>
			<div style={{ marginTop: 5 }}>
				<strong>3) APPORTER </strong> dans la mesure du possible :
				<ul style={{ marginTop: 0, marginBottom: 0 }}>
					<li>
						tous les résultats des examens biologiques (de sang) pendant les 5
						années qui précèdent.
					</li>
					<li>
						tous les résultats des examens de selles antérieurs effectués dans
						tout laboratoire depuis toujours.
					</li>
					<li>
						tous les résultats des examens de Fibroscopie, Coloscopie et
						d'Echographie abdomino-pelvienne depuis toujours.
					</li>
				</ul>
			</div>
			<div style={{ marginTop: 5 }}>
				<strong>
					4) VOUS POURREZ RÉALISER en même temps d'autres types de prélèvements
					(sang, urines, gynécologie, ORL, mycologie...) lors de votre venue à
					jeun au laboratoire.
				</strong>
			</div>
			<div style={{ marginTop: 5 }}>
				<strong>
					5) PREVOIR UNE ATTENTE DE 1h (mais tout dépend de la vitesse de votre
					transit) :{' '}
				</strong>{' '}
				un <i>questionnaire</i> sera remis sur place, il faudra préciser le{' '}
				<u>lieu</u> et la <u>date</u> des voyages.
			</div>

			<div
				onClick={() => props.setquestion('selles')}
				style={{
					textAlign: 'center',
					marginTop: 20,
					marginBottom: 20,
					color: 'blue',
					cursor: 'pointer',
					fontSize: 16,
				}}
			>
				<a>
					<u>
						<strong>
							Je déclare avoir pris connaissance des procédures de recueil et
							désire prendre rendez-vous.
						</strong>
					</u>
				</a>
			</div>
		</div>
	);
}
