import moment from 'moment';
import isWeekday, { isSellesDay } from './isWeekday';

export default function getSellesTimeSlots(date, labo) {
  let dates = [];

  if (labo === 'laboxv') {
    const deadline = moment(date).hour(9).minute(0).seconds(59);

    const now = moment(date).startOf('day').hour(7).minute(20).seconds(0);
    while (now.diff(deadline) < 0) {
      if ((now > moment() && isSellesDay(new Date(moment(now))), 'laboxv')) {
        dates.push(now.format('YYYY-MM-DD HH:mm'));
      }
      now.add(20, 'minutes');
    }
  }

  return dates;
}
