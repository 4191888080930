import React, { useState, useEffect, useCallback } from 'react';
import {
	Container,
	Header,
	Message,
	Tab,
	Button,
	Input,
	Select,
} from 'semantic-ui-react';

import DatePicker, { registerLocale } from 'react-datepicker';

import getCoronaTimeSlots from '../utils/getCoronaTimeSlots';

import getSellesTimeSlots from '../utils/getSellesTimeSlots';

import Form from './Form';
import Axios from 'axios';
import { api } from '../api';

import { Sentry } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import RdvTab from './RdvTab';

import fr from 'date-fns/locale/fr';
import isWeekday, { isCoronaWeekday } from '../utils/isWeekday';
import getTimeSlots from '../utils/getTimeSlots';
import ProcedureSelles from './ProcedureSelles';
import { useMediaQuery } from 'react-responsive';
import { useParams, useLocation } from 'react-router-dom';
import getDomicilesTimeSlots from '../utils/getDomicilesTimeSlots';
import DomicileForm from './DomicileForm';
import moment from 'moment-timezone';
registerLocale('fr', fr);

export default function Home() {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const [question, setquestion] = useState(null);
	const [date, setdate] = useState(new Date());
	const [slots, setslots] = useState(getTimeSlots(new Date()));

	const [sellesslots, setsellesslots] = useState(
		getSellesTimeSlots(new Date())
	);
	const [otherslots, setotherslots] = useState(new Date());
	const [domicilesslots, setdomicilesslots] = useState(
		getDomicilesTimeSlots(new Date())
	);
	const [slotselected, setslotselected] = useState(null);
	const [loading, setloading] = useState(true);
	const [error, seterror] = useState(null);
	const [reservedslots, setreservedslots] = useState([]);
	const [otherreservedslots, setotherreservedslots] = useState([]);
	const [reservedsellesslots, setreservedsellesslots] = useState([]);
	const [reserveddomicilesslots, setreserveddomicilesslots] = useState([]);
	const [isAuth, setisAuth] = useState(false);
	const [deleteerror, setdeleteerror] = useState(false);
	const [activeTab, setactiveTab] = useState(0);

	const [errorAuth, seterrorAuth] = useState(false);

	const [labo, setlabo] = useState(useLocation().pathname.split('/')[1]);

	const [message, setmessage] = useState('');

	async function auth(text) {
		try {
			await Axios.post(api + 'auth/login', { password: text });
			setisAuth(true);
		} catch (e) {
			setisAuth(false);
			seterrorAuth(true);
		}
	}

	function modifyMessage() {
		try {
			Axios.post(api + 'message', { message });
		} catch (e) {
			seterror(true);
		}
	}

	const panes = [
		{
			menuItem: 'Coronavirus',
			render: () => (
				<Tab.Pane attached={false}>
					<RdvTab
						labo={labo}
						setslotselected={setslotselected}
						setdeleteerror={setdeleteerror}
						get={get}
						slots={slots}
						reservedslots={reservedslots}
						isAuth={isAuth}
						type={'corona'}
					></RdvTab>
				</Tab.Pane>
			),
		},

		{
			menuItem: 'Examens de selles',
			render: () => (
				<Tab.Pane attached={false}>
					<RdvTab
						labo={labo}
						setslotselected={setslotselected}
						setdeleteerror={setdeleteerror}
						get={get}
						slots={sellesslots}
						reservedslots={reservedsellesslots}
						isAuth={isAuth}
						type={'selles'}
					></RdvTab>
				</Tab.Pane>
			),
		},
		{
			menuItem: 'Autres prélèvements',
			render: () => (
				<Tab.Pane attached={false}>
					<RdvTab
						labo={labo}
						setslotselected={setslotselected}
						setdeleteerror={setdeleteerror}
						get={get}
						slots={otherslots}
						reservedslots={otherreservedslots}
						isAuth={isAuth}
						type={'normal'}
					></RdvTab>
				</Tab.Pane>
			),
		},
		{
			menuItem: 'Domiciles',
			render: () => (
				<Tab.Pane attached={false}>
					<RdvTab
						labo={labo}
						setslotselected={setslotselected}
						setdeleteerror={setdeleteerror}
						get={get}
						slots={domicilesslots}
						reservedslots={reserveddomicilesslots}
						isAuth={isAuth}
						type={'domiciles'}
					></RdvTab>
				</Tab.Pane>
			),
		},
	];

	const get = useCallback(() => {
		getCreneaux(date, isAuth);
	});

	async function getCreneaux(date, isAuth = false) {
		setloading(true);
		seterror(false);

		date = moment(date).format('YYYY-MM-DD');

		try {
			if (isAuth) {
				var reservedslots = await Axios.post(api + 'slots/private', { date });
			} else {
				var reservedslots = await Axios.post(api + 'slots/get', { date });
			}

			setreservedslots(
				reservedslots.data.filter(
					(slot) => slot.type === 'corona' && slot.labo === labo
				)
			);
			setotherreservedslots(
				reservedslots.data.filter(
					(slot) => slot.type === 'normal' && slot.labo === labo
				)
			);

			setreservedsellesslots(
				reservedslots.data.filter(
					(slot) => slot.type === 'selles' && slot.labo === labo
				)
			);

			setreserveddomicilesslots(
				reservedslots.data.filter(
					(slot) => slot.type === 'domiciles' && slot.labo === labo
				)
			);

			setloading(false);
		} catch (e) {
			setloading(false);
			seterror(true);
		}
	}

	async function getMessage() {
		try {
			const message = await Axios.get(api + 'message');

			setmessage(message.data);
		} catch (e) {
			seterror(true);
		}
	}

	useEffect(() => {
		getMessage();
	}, []);

	useEffect(() => {
		const slots = getCoronaTimeSlots(date, labo);
		const otherslots = getTimeSlots(date, labo);
		const sellesslots = getSellesTimeSlots(date, labo);
		const domicilesslots = getDomicilesTimeSlots(date, labo);
		setslots(slots);
		setotherslots(otherslots);
		setsellesslots(sellesslots);
		setdomicilesslots(domicilesslots);

		get();
		const interval = setInterval(() => {
			get();
		}, 60000);

		return () => clearInterval(interval);
	}, [date, labo, isAuth]);

	const laboOptions = [
		{ key: 'laboxv', value: 'laboxv', text: 'LABO XV VAUGIRARD' },
		{ key: 'mozart', value: 'mozart', text: 'MOZART' },
		{ key: 'thiais', value: 'thiais', text: 'THIAIS' },
	];

	return (
		<Container
			style={{
				marginTop: '20px',
				textAlign: 'center',
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
			}}
		>
			{!labo ||
			(labo !== 'mozart' && labo !== 'thiais' && labo !== 'laboxv') ? (
				<div style={{ textAlign: 'center' }}>
					<Header.Subheader style={{ fontWeight: 'bold' }}>
						Choisissez un laboratoire.
					</Header.Subheader>
					<br />
					<Select
						placeholder='Choisissez votre laboratoire...'
						options={laboOptions}
						onChange={(e, { value }) => setlabo(value)}
					></Select>
				</div>
			) : (
				<>
					<div>
						<Header as='h1'>
							{labo === 'mozart' ? (
								<>
									<Header.Content>
										Prise de rendez-vous Labo Mozart
									</Header.Content>
									<Header.Subheader style={{ fontWeight: 'bold' }}>
										LABORATOIRE MOZART, 16 avenue Mozart - 75016 Paris
									</Header.Subheader>
								</>
							) : labo === 'laboxv' ? (
								<>
									<Header.Content>Prise de rendez-vous Labo XV</Header.Content>
									<Header.Subheader style={{ fontWeight: 'bold' }}>
										LABO XV VAUGIRARD, 353 rue de Vaugirard - 75015 Paris
									</Header.Subheader>
								</>
							) : (
								<>
									<Header.Content>
										Prise de rendez-vous Labo Thiais
									</Header.Content>
									<Header.Subheader style={{ fontWeight: 'bold' }}>
										Laboratoire de Biologie Médicale du Pavé de Grignon, 19 rue
										Pavé de Grignon - 94320 Thiais
									</Header.Subheader>
								</>
							)}
							{question && (
								<Header.Subheader>
									{question === 'corona' ? (
										<div>
											<div>
												Rendez-vous pour prélèvement coronavirus PCR
												nasopharyngé ou salivaire, seul ou couplé à d'autres
												prélèvements
											</div>
										</div>
									) : question === 'sellesprocedure' ? (
										<div></div>
									) : question === 'selles' ? (
										<div>
											<div>Rendez-vous pour un examen de selles</div>
										</div>
									) : question === 'domiciles' ? (
										<div>
											<div>
												Rendez-vous à votre domicile (mardi ou jeudi uniquement)
											</div>
										</div>
									) : (
										<div>
											<div>
												Rendez-vous pour tous types de prélèvements sauf PCR et
												examens de selles
											</div>
										</div>
									)}
								</Header.Subheader>
							)}
							{labo === 'thiais' && question !== 'domiciles' && (
								<Header.Subheader>
									<div style={{ color: 'red' }}>
										7h30-12h30 sans rendez-vous
									</div>
								</Header.Subheader>
							)}
						</Header>

						{slotselected ? (
							question === 'domiciles' ? (
								<div>
									<DomicileForm labo={labo} slot={slotselected}></DomicileForm>
								</div>
							) : (
								<div>
									<Form labo={labo} slot={slotselected}></Form>
								</div>
							)
						) : question === 'sellesprocedure' && !isAuth ? (
							<div>
								<ProcedureSelles setquestion={setquestion}></ProcedureSelles>
							</div>
						) : !question && !isAuth ? (
							<>
								<br />
								<br />
								<div>
									<Header>Vous venez pour...</Header>
									<br />
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											flexWrap: 'wrap',
											flexDirection: isDesktopOrLaptop ? 'row' : 'column',
										}}
									>
										<Button
											onClick={() => setquestion('corona')}
											style={{ marginTop: 10, marginRight: 5 }}
											color='blue'
											content='CORONAVIRUS PCR'
										></Button>
										{labo === 'laboxv' && (
											<Button
												onClick={() => setquestion('sellesprocedure')}
												style={{ marginTop: 10, marginRight: 5 }}
												color='violet'
												content='EXAMEN DE SELLES'
											></Button>
										)}
										{/* {labo === 'mozart' && (
											<Button
												onClick={() => setquestion('domiciles')}
												style={{ marginTop: 10, marginRight: 5 }}
												color='green'
												content='DOMICILES'
											></Button>
										)} */}
										{labo !== 'thiais' && (
											<Button
												onClick={() => setquestion('normal')}
												style={{ marginTop: 10, marginRight: 5 }}
												color='black'
												content='AUTRES EXAMENS'
											></Button>
										)}
									</div>
								</div>
							</>
						) : (
							<>
								{deleteerror && (
									<Message
										error
										content={'Erreur lors de la suppression de ce créneau.'}
									></Message>
								)}

								<DatePicker
									locale='fr'
									filterDate={
										question == 'corona'
											? (date) => isCoronaWeekday(date, labo)
											: (date) => isWeekday(date, labo)
									}
									dateFormat='EEEE dd/MM/yyyy'
									minDate={new Date()}
									onChange={(date) => setdate(date)}
									selected={date}
								></DatePicker>
								<div>
									Cliquez ci-dessus sur la date pour sélectionner une date de
									rendez-vous, puis réservez en dessous un créneau.
								</div>
								<div style={{ marginTop: '20px' }}></div>
								<Container>
									{loading ? (
										<Sentry></Sentry>
									) : error ? (
										<Message
											error
											content='Erreur lors de la récupération des créneaux.'
										></Message>
									) : isAuth ? (
										<>
											<Input
												value={message}
												onChange={(e) => setmessage(e.target.value)}
												label='Message'
											></Input>
											<Button onClick={() => modifyMessage()}>Ok</Button>
											<Tab
												activeIndex={activeTab}
												onTabChange={(e, data) =>
													setactiveTab(data.activeIndex)
												}
												menu={{ secondary: true }}
												panes={panes}
											/>
										</>
									) : question === 'corona' ? (
										<RdvTab
											labo={labo}
											setslotselected={setslotselected}
											setdeleteerror={setdeleteerror}
											get={get}
											slots={slots}
											reservedslots={reservedslots}
											isAuth={isAuth}
											type={'corona'}
										></RdvTab>
									) : question === 'selles' ? (
										<RdvTab
											labo={labo}
											setslotselected={setslotselected}
											setdeleteerror={setdeleteerror}
											get={get}
											slots={sellesslots}
											reservedslots={reservedsellesslots}
											isAuth={isAuth}
											type={'selles'}
										></RdvTab>
									) : question === 'domiciles' ? (
										<RdvTab
											labo={labo}
											setslotselected={setslotselected}
											setdeleteerror={setdeleteerror}
											get={get}
											slots={domicilesslots}
											reservedslots={reserveddomicilesslots}
											isAuth={isAuth}
											type={'domiciles'}
										></RdvTab>
									) : (
										<RdvTab
											labo={labo}
											setslotselected={setslotselected}
											setdeleteerror={setdeleteerror}
											get={get}
											slots={otherslots}
											reservedslots={otherreservedslots}
											isAuth={isAuth}
											type={'normal'}
										></RdvTab>
									)}
								</Container>
							</>
						)}
					</div>

					<div
						style={{
							display: 'flex',
							marginTop: 10,
							flexGrow: 1,
							justifyContent: 'center',
							alignItems: 'flex-end',
						}}
					>
						<input
							onChange={(e) => auth(e.target.value)}
							style={{ border: 'none' }}
						></input>
					</div>
				</>
			)}
		</Container>
	);
}
