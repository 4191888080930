import React from 'react';
import { Button, Message } from 'semantic-ui-react';
import moment from 'moment-timezone';
import deleteSlot from '../services/deleteSlot';
import isWeekday, { isCoronaWeekday } from '../utils/isWeekday';

import blockSlot from '../services/blockSlot';
import AuthDomicileColumns from './AuthDomicileColumns';

export default function RdvTab(props) {
	const {
		reservedslots,
		slots,
		isAuth,
		setdeleteerror,
		get,
		setslotselected,
		type,
		labo,
	} = props;

	const slotIsReserved = (slot) => {
		return reservedslots.some(
			(reservedslot) =>
				moment.tz(slot, 'Europe/Paris').utc().format() ==
				moment.tz(reservedslot.date, 'Europe/Paris').utc().format()
		);
	};

	const slotFind = (slot) => {
		return reservedslots.find(
			(reservedslot) =>
				moment.tz(slot, 'Europe/Paris').utc().format() ==
				moment.tz(reservedslot.date, 'Europe/Paris').utc().format()
		);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{slots.length === 0 && (
				<Message
					info
					content={
						'Aucun créneau disponible à cette date, veuillez sélectionner une autre date ci-dessus.'
					}
				></Message>
			)}
			{isAuth && (
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<strong>{reservedslots.length}</strong> &nbsp; rendez-vous pris.
				</div>
			)}
			{slots.map((slot) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						padding: '3px',
						borderBottom: '1px solid #ECF0F1',
					}}
				>
					{!isAuth && (
						<div style={{ flex: 1 }}>
							<Button
								disabled={
									type === 'corona'
										? slotIsReserved(slot) ||
										  !isCoronaWeekday(new Date(moment(slot)), labo)
										: slotIsReserved(slot) ||
										  !isWeekday(new Date(moment(slot)), labo)
								}
								compact
								onClick={() => setslotselected({ slot, type })}
							>
								{' '}
								Réserver{' '}
							</Button>
						</div>
					)}

					{isAuth && (
						<>
							<div style={{ flex: 1 }}>
								<Button
									color={
										type === 'corona'
											? 'purple'
											: type === 'selles'
											? 'orange'
											: type === 'domiciles'
											? 'black'
											: 'red'
									}
									disabled={!slotIsReserved(slot)}
									compact
									onClick={() =>
										deleteSlot(slotFind(slot), setdeleteerror, get)
									}
								>
									{' '}
									Retirer{' '}
								</Button>
							</div>
							<div style={{ flex: 1 }}>
								<Button
									color={
										type === 'corona'
											? 'yellow'
											: type === 'selles'
											? 'brown'
											: type === 'domiciles'
											? 'olive'
											: 'blue'
									}
									disabled={slotIsReserved(slot)}
									compact
									onClick={() =>
										blockSlot(moment(slot), type, setdeleteerror, get, labo)
									}
								>
									{' '}
									Bloquer{' '}
								</Button>
							</div>
						</>
					)}

					<div
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{type !== 'domiciles' &&
							moment.tz(slot, moment.tz.guess()).format('HH:mm')}
					</div>

					{isAuth && (
						<>
							<div
								style={{
									display: 'flex',
									flex: 1,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{slotFind(slot)?.firstName}
							</div>
							<div
								style={{
									display: 'flex',
									flex: 1,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{slotFind(slot)?.lastName}
							</div>
							<div
								style={{
									display: 'flex',
									flex: 1,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{slotFind(slot)?.phone}
							</div>
							{type === 'domiciles' && (
								<AuthDomicileColumns
									reservedslots={reservedslots}
									slot={slot}
								></AuthDomicileColumns>
							)}

							{type === 'selles' && (
								<div
									style={{
										display: 'flex',
										flex: 1,
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									{slotFind(slot)?.medecin}
								</div>
							)}
						</>
					)}
					{!isAuth && (
						<div
							style={{
								display: 'flex',
								flex: 1,
								justifyContent: 'center',
								alignItems: 'center',
								color: slotIsReserved(slot)
									? '#ff9999'
									: // : (moment(slot).hour() === 7 ||
									  //     moment(slot).hour() === 9) &&
									  //   moment(slot).minute() === 15
									  // ? '#89C4F4'
									  '#2ABB9B',
							}}
						>
							{slotIsReserved(slot)
								? 'Réservé'
								: // : (moment(slot).hour() === 7 ||
								  //     moment(slot).hour() === 9) &&
								  //   moment(slot).minute() === 15
								  // ? 'Prises de sang uniquement'
								  'Libre'}
						</div>
					)}
				</div>
			))}
		</div>
	);
}
